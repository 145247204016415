import PropTypes from 'prop-types';

export default {
  listContent: PropTypes.arrayOf(
    PropTypes.shape({
      // TODO: Definir estes Proptypes
    })
  ),
  idComponentContext: PropTypes.string,
  isMobile: PropTypes.bool,
  titleContent: PropTypes.string,
};
