export default function removeQueryParamsFromUrl(paramsToRemove) {
  const url = window.location.href;
  const objectUrl = new URL(url);
  const params = new URLSearchParams(objectUrl.search.slice(1));

  if (paramsToRemove.length > 0) {
    for (let i = 0; i < paramsToRemove.length; i += 1) {
      const paramKey = paramsToRemove[i];
      params.delete(paramKey);
    }
  }

  return params.toString();
}
