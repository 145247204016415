import { call, select, put } from 'redux-saga/effects';

import api from '../../config/api';
import environmentVariables from '../../config/environmentVariables';
import { getQueryParam } from '../../util/getQueryParam';
import normalizeDnsInfo from '../../util/normalizers/normalizeDnInfo';
import { Creators as AppActions } from '../ducks/app';
import { Creators as whitelabelActions } from '../ducks/whiteLabel';

export function* getWhiteLabel() {
  const { GATEWAY_APP_KEY, MGM_AUTH } = environmentVariables;

  const KEY = GATEWAY_APP_KEY;
  const token = MGM_AUTH;

  const config = { headers: { Authorization: `Basic ${token}` } };
  const dn = getQueryParam('dn');
  const { app, clientId } = yield select(state => state.share.shareInfo);

  const { data } = yield call(
    api.get,
    `/mgm-dn/v1/indicado/whitelabel?dn=${dn}&gw-app-key=${KEY}&id=${clientId}&app=${app}`,
    config
  );
  yield put(AppActions.setTheme(data.whiteLabel.cssTema));
  const normalizedDns = normalizeDnsInfo(data);
  yield put(whitelabelActions.setData(normalizedDns));
}

export function* getWhiteLabelByCardCode() {
  const { GATEWAY_APP_KEY, MGM_AUTH } = environmentVariables;

  const CARD_CODE = getQueryParam('cartao');
  const KEY = GATEWAY_APP_KEY;
  const token = MGM_AUTH;
  const cardType = 'ITAUCARD';

  const config = { headers: { Authorization: `Basic ${token}` } };

  const response = yield call(
    api.get,
    `/mgm-dn/v1/indicado/whitelabel/${CARD_CODE}?cartaoTipo=${cardType}&gw-app-key=${KEY}`,
    config
  );

  yield put(AppActions.setTheme(response.data.whiteLabel.cssTema));
  const normalizedDns = normalizeDnsInfo(response.data);
  yield put(whitelabelActions.setData(normalizedDns));
}
