/**
 * Used to sanitize and parse html strings
 * while removing the unneeded nbsp
 *
 * @param {string} htmlString
 */

import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

function normalizeNoContextUrl(htmlString, linkDescription) {
  const noContextUrlContentRegex = /(<a href=.*>)([Ss]aiba [Mm]ais)(<\/a>)/gm;
  return htmlString.replace(
    noContextUrlContentRegex,
    `$1$2 sobre ${linkDescription}$3`
  );
}

export default (htmlString, linkDescription) => {
  // TODO alterar o codigo da funcao inteira pelo comentado abaixo quando validarmos acessibilidade
  // const removedNbspString = htmlString.replace(/<p>(\s?)&nbsp;(\s?)<\/p>/gi, '');
  // return parse(DOMPurify.sanitize(removedNbspString));

  const linkMasterCardSurpreenda =
    '<a href="https://surpreenda.naotempreco.com.br/rewards/vouchers/surpreenda/paodeacucar" target="_blank">Saiba mais no site</a>';
  let newHtemlString = htmlString;
  let removedNbspString;

  // trexo de codigo abaixo foi implementado como tratativa de remocao do link do mastercard surpreenda
  if (htmlString.includes(linkMasterCardSurpreenda)) {
    newHtemlString = newHtemlString.replace(linkMasterCardSurpreenda, '');
  }

  removedNbspString = newHtemlString.replace(/<p>(\s?)&nbsp;(\s?)<\/p>/gi, '');

  // trexo de codigo abaixo foi implementado como tratativa de normalizacao da url sem contexto
  if (linkDescription) {
    removedNbspString = normalizeNoContextUrl(
      removedNbspString,
      linkDescription
    );
  }

  return parse(DOMPurify.sanitize(removedNbspString));
};
