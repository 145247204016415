import { normalizeName } from '../../util/normalizers/normalizeName';
/**
 * * Types
 */
export const Types = {
  GET_SHARE_REQUEST: 'share/REQUEST',
  GET_SHARE_SUCCESS: 'share/SUCCESS',
  SET_ID: 'share/SET_ID',
};

/**
 * * Reducer
 */
export const INITIAL_STATE = {
  shareInfo: {
    clientId: '',
    firstName: '',
    app: '',
  },
};

export default function share(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_SHARE_REQUEST:
      return {
        ...state,
      };

    case Types.GET_SHARE_SUCCESS:
      return {
        ...state,
        shareInfo: {
          ...state.shareInfo,
          clientId: action.payload.shareInfo.clientId,
          firstName: normalizeName(action.payload.shareInfo.firstName),
          app: action.payload.shareInfo.app,
        },
      };

    case Types.SET_ID:
      return {
        ...state,
        id: action.payload.id,
      };

    default:
      return state;
  }
}

/**
 * * Actions
 */
export const Creators = {
  getShareRequest: () => ({
    type: Types.GET_SHARE_REQUEST,
  }),

  getShareSuccess: shareInfo => ({
    type: Types.GET_SHARE_SUCCESS,
    payload: { shareInfo },
  }),

  setId: id => ({
    type: Types.SET_ID,
    payload: { id },
  }),
};
