import React, { useState } from 'react';

import PropTypes from 'prop-types';

import parseSanitizedHtml from '../../util/parseSanitizedHtml';
import './_Collapsible.scss';

function Collapsible({ idComponentContext, index, icon, title, content }) {
  const [opened, setOpen] = useState(false);
  const idComponent = 'collapsible';

  function toggleOpen() {
    setOpen(!opened);
  }

  return (
    <div
      id={`${idComponent}${index}_${idComponentContext}`}
      className="collapsible-wrapper"
    >
      <button
        type="button"
        className="collapsible-button"
        id={`btnToggle${index}_${idComponent}_${idComponentContext}`}
        aria-expanded={opened}
        aria-controls={`lblContent${index}_${idComponent}_${idComponentContext}`}
        onClick={() => toggleOpen()}
      >
        <h3 id={`title-collapsible-${index}`} className="collapsible-title">
          {title}
        </h3>
        <i
          id={`bullet-collapsible-${index}`}
          className={`icon ${icon} ${opened ? 'opened' : 'closed'}`}
          aria-hidden
        />
      </button>

      <div
        role="region"
        id={`lblContent${index}_${idComponent}_${idComponentContext}`}
        aria-hidden={!opened}
        aria-labelledby={`btnToggle${index}_${idComponent}_${idComponentContext}`}
        className={`collapsible-content ${opened ? 'opened' : 'closed'}`}
      >
        <div className="collapsible-text">
          {opened && parseSanitizedHtml(content, title)}
        </div>
      </div>
    </div>
  );
}

Collapsible.propTypes = {
  idComponentContext: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default Collapsible;
