import { call, put } from 'redux-saga/effects';

import api from '../../config/api';
import environmentVariables from '../../config/environmentVariables';
import { getQueryParam } from '../../util/getQueryParam';
import { Creators as ShareActions } from '../ducks/share';

export function* getShare() {
  const { GATEWAY_APP_KEY, MGM_AUTH } = environmentVariables;
  const CHPRAS = getQueryParam('id');
  const KEY = GATEWAY_APP_KEY;
  const token = MGM_AUTH;
  const config = { headers: { Authorization: `Basic ${token}` } };
  const response = yield call(
    api.get,
    `/mgm/v2/cliente?gw-app-key=${KEY}&token=${CHPRAS}`,
    config
  );
  return yield put(ShareActions.getShareSuccess(response.data));
}
