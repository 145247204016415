import { track } from '../track';

export default function* pageLoad(action) {
  const { analytics } = action;

  if (action.product && action.product.items.length > 0)
    analytics.product = action.product;

  yield track(analytics);
}
