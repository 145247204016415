function analyticsPage(errorDescription) {
  return {
    secao: 'MemberGetMember',
    subSecao1: 'CartaoIndicadoPorAmigo',
    url: 'www.itau.com.br/cartoes',
    nome: 'IC:NL:NCC:MemberGetMember:CartaoIndicadoPorAmigo:Erro',
    templateName: 'home',
    nomeDoErro: errorDescription,
  };
}

const pageName = 'ErrorPage';

export { analyticsPage, pageName };
