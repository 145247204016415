import environment from '../../config/environmentVariables';
import { sendMessage } from '../../endpoints/googleChatMessage';

const getHref = linkHtml => {
  const { GOOGLE_CHAT_API_URL } = environment;
  const anchorRegex = /<[a?>](.*?)<\/a>/; // this regex filters just the anchor tag
  const hrefRegex = /\s+(?:[^>]*?\s+)?href="([^"]*)"/; // this regex filters just the content of a href atribule
  let linkResponse = linkHtml;
  try {
    if (linkHtml.match(anchorRegex)) {
      // eslint-disable-next-line prefer-destructuring
      linkResponse = linkHtml.match(anchorRegex)[1].match(hrefRegex)[1];
    }
  } catch (error) {
    sendMessage(error, 'getHref', GOOGLE_CHAT_API_URL);
  }
  return linkResponse;
};

export default getHref;
