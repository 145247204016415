import React from 'react';

import './_ProductDetailItem.scss';
import getHref from '../../../../util/htmlTagHandlers/getHref';
import productDetailItemPropTypes from './productDetailItemPropTypes';

const idComponent = 'productDetailItemWhiteLabel';

const ProductDetailItem = ({
  idComponentContext,
  itemIcon,
  itemTitle,
  itemText,
  itemLink,
  itemUrl,
  index,
}) => (
  <div
    className="productdetailwhitelabel-item-wrapper"
    id={`${index}_${idComponent}_${idComponentContext}`}
  >
    <i
      className={`icon ${itemIcon}`}
      id={`imgIcon${index}_${idComponent}_${idComponentContext}`}
      aria-hidden
    />

    <div className="item-content">
      <h3
        className="item-title"
        id={`lblTitle${index}_${idComponent}_${idComponentContext}`}
      >
        {itemTitle}
      </h3>

      <div className="item-description">
        <p
          className="item-text"
          id={`lblText${index}_${idComponent}_${idComponentContext}`}
        >
          {itemText}
          {itemUrl !== '' && (
            <a
              id={`lnkNav${index}_${idComponent}_${idComponentContext}`}
              className="item-link"
              href={getHref(itemUrl)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {itemLink}
            </a>
          )}
        </p>
      </div>
    </div>
  </div>
);

ProductDetailItem.propTypes = productDetailItemPropTypes;

ProductDetailItem.defaultProps = {
  itemLink: '',
  itemUrl: '',
};

export default ProductDetailItem;
