import React from 'react';

import './_ErrorContentRedirect.scss';
import DefaultErrorImageDesktop from '../assets/default-error-desktop.png';
import DefaultErrorImageMobile from '../assets/default-error-mobile.png';
import defaultErrorContentPropTypes from './defaultErrorContentPropTypes';

const idComponentContext = 'componentHeader';

const ErrorContent = ({
  title,
  description,
  link,
  linkNoRetry,
  retry,
  retryAction,
  descriptionList,
}) => (
  <div className="error-content-redirect">
    <div className="error-content-wrapper-redirect">
      <div className="error-header-block-redirect">
        <img
          alt=""
          src={DefaultErrorImageMobile}
          className="error-img-mobile-redirect"
        />
        <img
          alt=""
          src={DefaultErrorImageDesktop}
          className="error-img-desktop-redirect"
        />
      </div>
      <div className="error-content-block-redirect">
        <h2
          id={`lblTitle_${idComponentContext}`}
          className="error-title-redirect"
          aria-label="algo deu errado"
        >
          {title}
        </h2>
        <div
          id={`lblContent_${idComponentContext}`}
          className="error-description-redirect-container"
        >
          <p className="error-description-title">{description}</p>

          {descriptionList.length > 0 && (
            <ul className="error-description-list-redirect">
              {descriptionList.map((descriptionItem, index) => (
                <li
                  key={descriptionItem}
                  className="error-description-item-redirect"
                >
                  {`${index + 1}- ${descriptionItem}`}
                </li>
              ))}
            </ul>
          )}
        </div>
        {retry ? (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            role="link"
            id={`lnkRetry_${idComponentContext}`}
            type="button"
            tabIndex={0}
            className="retry-button-redirect"
            onClick={() => {
              retryAction();
            }}
            onKeyDown={() => {
              retryAction();
            }}
          >
            {link}
          </a>
        ) : (
          <p
            id={`lblRetry_${idComponentContext}`}
            className="error-description-redirect"
          >
            {linkNoRetry}
          </p>
        )}
      </div>
    </div>
  </div>
);

ErrorContent.propTypes = defaultErrorContentPropTypes;

ErrorContent.defaultProps = {
  title: 'Página de erro',
  description: 'Algo deu errado',
  link: 'tentar novamente',
  retry: false,
  descriptionList: [],
};

export default ErrorContent;
