import PropTypes from 'prop-types';

export default {
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  retry: PropTypes.bool,
  retryAction: PropTypes.func.isRequired,
  descriptionList: PropTypes.arrayOf(PropTypes.node),
  linkNoRetry: PropTypes.string.isRequired,
};
