import { call } from 'redux-saga/effects';

import environment from '../../../config/environmentVariables';
import constants from '../../../constants';
import { sendMessage } from '../../../endpoints/googleChatMessage';
import analyticsErrorHandler from './handlers/analyticsErrorHandler';
import genericErrorHandler from './handlers/genericErrorHandler';
import redirectErrorHandler from './handlers/redirectErrorHandler';

const {
  ERROR_TYPES: {
    ANALYTICS: { ITAU_TRACK },
    FLOW: { APP_INIT_REDIRECT },
  },
} = constants;

export default function* errorHandler(action) {
  const { GOOGLE_CHAT_API_URL } = environment;
  const { error, whereErrorOccurred } = action.payload;
  const isAnalyticsError = whereErrorOccurred === ITAU_TRACK;
  const isRedirectError = whereErrorOccurred === APP_INIT_REDIRECT;

  sendMessage(error, whereErrorOccurred, GOOGLE_CHAT_API_URL);
  if (isAnalyticsError) {
    return yield call(analyticsErrorHandler, whereErrorOccurred);
  }
  if (isRedirectError) {
    return yield call(redirectErrorHandler, whereErrorOccurred);
  }
  return yield call(genericErrorHandler, error, whereErrorOccurred);
}
