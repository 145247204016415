const extractParametersFromUrl = () => {
  return {
    chid: new URL(document.location).searchParams.get('chid'),
    campanha: new URL(document.location).searchParams.get('campanha'),
    dn: new URL(document.location).searchParams.get('dn'),
    tipoDispositivo: new URL(document.location).searchParams.get('disp'),
    fase: new URL(document.location).searchParams.get('fase'),
    cpg: new URL(document.location).searchParams.get('cpg'),
  };
};

export { extractParametersFromUrl };
