import React from 'react';

import ActionButton from '../../../components/ActionButton/ActionButton';
import PageIntroItem from './PageIntroItem/PageIntroItem';
import pageIntroPropTypes from './pageIntroPropTypes';
import './_PageIntro.scss';

const idComponent = 'pageIntroWhiteLabel';

const PageIntro = ({
  idComponentContext,
  introBackImage,
  introTitle,
  introImage,
  introImageAlt,
  introDescriptions,
  btnTitle,
  btnClick,
  anchor,
}) => (
  <section
    id={anchor}
    className="pageintrowhitelabel-wrapper"
    style={{ backgroundImage: `url(${introBackImage})` }}
  >
    <div id={`${idComponent}_${idComponentContext}`} className="container">
      <img
        className="intro-image desktop-view"
        src={introImage}
        alt={introImageAlt}
        aria-hidden
      />

      <div className="intro-content">
        <div className="intro-header">
          <img
            className="intro-image mobile-view"
            src={introImage}
            alt={introImageAlt}
            aria-hidden
          />
          <h2
            className="intro-title"
            id={`lblTitle_${idComponent}_${idComponentContext}`}
          >
            {introTitle}
          </h2>
        </div>

        <ul className="intro-descriptions">
          {/*
          // TODO Ajustar renderização abaixo no itaucard
          */}
          {introDescriptions &&
            introDescriptions.map((contentItem, index) => {
              return contentItem.detail.text !== '' ? (
                <li
                  key={`${contentItem.icon.name}_${contentItem.detail.text}`}
                  className="description-item"
                  id={`lstItem${index}_${idComponent}_${idComponentContext}`}
                >
                  <PageIntroItem
                    descIcon={contentItem.icon.name}
                    descTitle={contentItem.detail.text}
                  />
                </li>
              ) : (
                <div key={contentItem} aria-hidden />
              );
            })}
        </ul>

        <ActionButton
          idComponentContext={idComponentContext}
          btnTitle={btnTitle}
          onClick={btnClick}
        />
      </div>
    </div>
  </section>
);

PageIntro.propTypes = pageIntroPropTypes;

PageIntro.defaultProps = {
  introBackImage: '',
  introImageAlt: '',
};

export default PageIntro;
