import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import { AppCardCode } from '../App/containers/AppCardCode';
import { AppDn } from '../App/containers/AppDn';
import AppRedirect from '../App/containers/AppRedirect';
import environment from '../config/environmentVariables/index';
import constants from '../constants';
import { sendMessage } from '../endpoints/googleChatMessage';
import CardAcquisition from '../pages/CardAcquisition/CardAcquisition';
import DefaultError from '../pages/DefaultError/DefaultError';
import { getQueryParam } from '../util/getQueryParam';
import getLocalStorageQueryParams from './getLocalStorageQueryParams';
import ProtectedRoute from './ProtectedRoute';

const {
  ROUTES: {
    OPTIONAL_PATH_PREFIX,
    HOME,
    CARD_ACQUISITION,
    CARD_COD,
    CARD_COD_CARD_ACQUISITION,
    REDIRECT,
  },
} = constants;

export default function Routes() {
  const { GOOGLE_CHAT_API_URL } = environment;
  const acquisitionPermition = useSelector(
    state => state.routesControl.permitedRoutes.acquisition
  );
  return (
    <ErrorBoundary
      onError={error =>
        sendMessage(error, 'Erro genérico de javascript.', GOOGLE_CHAT_API_URL)
      }
      FallbackComponent={DefaultError}
    >
      <BrowserRouter basename={OPTIONAL_PATH_PREFIX}>
        <Switch>
          <Route exact path={HOME} component={AppDn} />
          <Route exact path={CARD_COD} component={AppCardCode} />
          <Route exact path={REDIRECT} component={AppRedirect} />
          <ProtectedRoute
            redirectionRoute={`${HOME}${getLocalStorageQueryParams(['id'])}`}
            path={CARD_ACQUISITION}
            canActivate={acquisitionPermition}
            component={CardAcquisition}
          />
          <ProtectedRoute
            redirectionRoute={`${CARD_COD}${getLocalStorageQueryParams([
              'cartao',
            ])}`}
            path={CARD_COD_CARD_ACQUISITION}
            canActivate={acquisitionPermition}
            component={CardAcquisition}
          />
          <Route
            render={() => <Redirect to={`${HOME}?id=${getQueryParam('id')}`} />}
          />
        </Switch>
      </BrowserRouter>
    </ErrorBoundary>
  );
}
