export const Types = {
  APP_INIT_REDIRECT: 'redirect/init',
  STOP_LOADING: 'redirect/stopLoading',
  SET_MESSAGE: 'redirect/setMessage',
  DISPATCH_PAGE_LOAD: 'redirect/dispatchPageLoad',
};

export const INITIAL_STATE = {
  loading: true,
  parameters: {},
  message: '',
};

export default function appReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.APP_INIT_REDIRECT:
      return {
        ...state,
        loading: true,
      };

    case Types.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };

    case Types.SET_MESSAGE:
      return {
        ...state,
        message: action.payload.message,
      };

    case Types.DISPATCH_PAGE_LOAD:
      return {
        ...state,
        action: action.payload.dn,
      };

    default:
      return state;
  }
}

export const Creators = {
  appInitRedirect: () => ({
    type: Types.APP_INIT_REDIRECT,
  }),

  dispatchPageLoad: dn => ({
    type: Types.DISPATCH_PAGE_LOAD,
    payload: { dn },
  }),

  stopLoading: () => ({
    type: Types.STOP_LOADING,
  }),

  setMessage: message => ({
    type: Types.SET_MESSAGE,
    payload: { message },
  }),
};
