import React from 'react';

import footerPropTypes from './footerPropTypes';
import './_Footer.scss';

const Footer = ({ idComponentContext, companyName, companyAddress }) => (
  <footer className="footer-whitelabel" id={`footer_${idComponentContext}`}>
    <div className="container">
      <div className="addressBox">
        <p id="lbl1_footerWhiteLabel_pageWhiteLabel" className="address">
          {companyName}
        </p>
        <p id="lbl2_footerWhiteLabel_pageWhiteLabel" className="address">
          {companyAddress}
        </p>
      </div>

      <div className="last-line" aria-hidden />
    </div>
  </footer>
);

Footer.propTypes = footerPropTypes;

export default Footer;
