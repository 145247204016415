import React from 'react';

import styled from 'styled-components';

import dynamicBackgroundPropTypes from './dynamicBackgroundPropTypes';
import './_DynamicBackground.scss';

const Wrapper = styled.div`
  background-image: url('${props => props.imageSrc}');
  background-color: ${props => props.backgroundColor};
`;

export function DynamicBackground({ imageSrc, backgroundColor, children }) {
  return (
    <Wrapper
      imageSrc={imageSrc}
      backgroundColor={backgroundColor}
      className="dynamic-background-component"
    >
      {children}
    </Wrapper>
  );
}

DynamicBackground.propTypes = dynamicBackgroundPropTypes;

DynamicBackground.defaultProps = {
  backgroundColor: '',
};
