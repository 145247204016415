import React from 'react';

import SimpleCarousel from '../../../components/SimpleCarousel/SimpleCarousel';
import benefitsListPropTypes from './benefitsListPropTypes';
import ContentItem from './ContentItem/ContentItem';
import ContentItemWeb from './ContentItemWeb/ContentItemWeb';
import './_BenefitsList.scss';

const idComponent = 'benefitList';

const BenefitsList = ({
  listContent,
  idComponentContext,
  isMobile,
  titleContent,
  anchor,
}) => (
  <section id={anchor} className="component-benefitslist-whitelabel-wrapper">
    <div className={`${isMobile ? '' : 'container'}`}>
      {!isMobile && <h2 className="title-list">{titleContent}</h2>}
      <SimpleCarousel idComponentContext={idComponentContext}>
        {listContent.map((item, index) => (
          <li
            className="content-item"
            key={`${idComponentContext}_${idComponent}_item${item.title.text.trim()}`}
          >
            {isMobile ? (
              <ContentItem
                idComponentContext={idComponentContext}
                index={index}
                title={item.title.text}
                imageUrl={item.image.url}
                text={item.description.text}
                linkUrl={item.link.text !== '' ? item.link.url : ''}
                linkAria={item.link.text !== '' ? item.link.text : ''}
              />
            ) : (
              <ContentItemWeb
                idComponentContext={idComponentContext}
                index={index}
                title={item.title.text}
                imageUrl={item.image.url}
                text={item.description.text}
                linkUrl={item.link.text !== '' ? item.link.url : ''}
                linkAria={item.link.text !== '' ? item.link.text : ''}
              />
            )}
          </li>
        ))}
      </SimpleCarousel>
    </div>
  </section>
);

BenefitsList.propTypes = benefitsListPropTypes;

BenefitsList.defaultProps = {
  listContent: [],
  idComponentContext: '',
  isMobile: false,
  titleContent: '',
};

export default BenefitsList;
