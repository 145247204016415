import PropTypes from 'prop-types';

export default {
  appInit: PropTypes.func.isRequired,
  imagesItaucard: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  }).isRequired,
  linksItaucard: PropTypes.shape({ url: PropTypes.string }).isRequired,
  browserWhitelabel: PropTypes.string,
  customerChpras: PropTypes.string.isRequired,
};
