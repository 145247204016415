import PropTypes from 'prop-types';

export default {
  changePermition: PropTypes.func.isRequired,
  analytics: PropTypes.shape().isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  cardDn: PropTypes.number.isRequired,
  customer: PropTypes.shape({
    name: PropTypes.string.isRequired,
    chpras: PropTypes.string.isRequired,
  }).isRequired,
  whitelabelContent: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  }).isRequired,
  images: PropTypes.shape({
    'main-background-landing-image': PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.string,
    }).isRequired,
    'main-application-logo-positive-image': PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.string,
    }).isRequired,
    'main-application-logo-negative-image': PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.string,
    }).isRequired,
    'main-landing-card-image': PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.string,
    }).isRequired,
  }).isRequired,
  texts: PropTypes.shape({
    'main-landing-title': PropTypes.shape({
      text: PropTypes.string,
    }).isRequired,
    'pageintro-landing-title': PropTypes.shape({
      text: PropTypes.string,
    }).isRequired,
    'benefitslist-landing-title': PropTypes.shape({
      text: PropTypes.string,
    }).isRequired,
    'carddetaillist-landing-title': PropTypes.shape({
      text: PropTypes.string,
    }).isRequired,
    'dndetaillist-landing-title': PropTypes.shape({
      text: PropTypes.string,
    }).isRequired,
    'footer-landing-company-name': PropTypes.shape({
      text: PropTypes.string,
    }).isRequired,
    'footer-landing-company-address': PropTypes.shape({
      text: PropTypes.string,
    }).isRequired,
  }).isRequired,
  links: PropTypes.shape({
    'main-landing-button-acquistion': PropTypes.shape({
      url: PropTypes.string,
      text: PropTypes.string,
    }).isRequired,
  }).isRequired,
  icons: PropTypes.shape({
    'dndetailitem-collapsible-landing-icon': PropTypes.shape({
      name: PropTypes.string,
    }).isRequired,
  }).isRequired,
};
