import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import _ from 'lodash';
import { bindActionCreators } from 'redux';

import Card from '../../components/Card/Card';
import NavMenu from '../../components/NavMenu/NavMenu';
import WebPageHeader from '../../components/WebPageHeader/WebPageHeader';
import constants from '../../constants';
import store from '../../store';
import { Creators as AppActions } from '../../store/ducks/app';
import { Creators as RoutesControlActions } from '../../store/ducks/routesControl';
import { Creators as ShareActions } from '../../store/ducks/share';
import { Creators as WhiteLabelActions } from '../../store/ducks/whiteLabel';
import DefaultTemplate from '../../template/DefaultTemplate';
import getAllQueryParamKeys from '../../util/getAllQueryParamKeys';
import removeQueryParamsFromUrl from '../../util/removeQueryParamsFromUrl';
import whiteLabelContentsObjectStructurer from '../../util/whiteLabelContentsObjectStructurer';
import BenefitsList from './BenefitsList/BenefitsList';
import CollapsibleList from './CollapsibleList/CollapsibleList';
import DnDetailList from './DnDetailList/DnDetailList';
import { DynamicBackground } from './DynamicBackground/DynamicBackground';
import Footer from './Footer/Footer';
import { analyticsClick, analytics } from './HomePageTag';
import homePropTypes from './homePropTypes';
import PageIntro from './PageIntro/PageIntro';
import ProductDetailList from './ProductDetailList/ProductDetailList';
import Title from './Title/Title';

import './_Home.scss';

const {
  RESOURCES: { NAV_MENU },
} = constants;

const idComponentContext = 'pageHomeWhiteLabel';
const observerOptions = {
  rootMargin: '-100px 0px 190px 0px',
  threshold: {
    mainTitleSemiVisible: 0.96,
    mainTitleFullyVisible: 0.97,
  },
};
const navMenuParams = {
  filter: /navmenu-landing/,
  contentTypes: ['links'],
};

const pageIntroParams = {
  filter: /pageintro-landing/,
  contentTypes: ['texts', 'images', 'icons'],
};

const benefitsListParams = {
  filter: /benefititem-landing/,
  contentTypes: ['texts', 'images', 'links'],
};

const cardDetailParams = {
  filter: /carddetailitem-landing/,
  contentTypes: ['texts', 'links', 'icons'],
};

const dnDetailListParams = {
  filter: /dndetailitem-landing/,
  contentTypes: ['texts'],
};

const collapsibleListParams = {
  filter: /dndetailitem-collapsible-landing/,
  contentTypes: ['texts'],
};

const getBackgroundImg = (img, isMobile) => {
  if (isMobile) {
    return img.urlMobile ? img.urlMobile : null;
  }
  return img.url;
};

const {
  ROUTES: { CARD_ACQUISITION, CARD_COD_CARD_ACQUISITION },
  SCREEN_SIZES,
} = constants;

class Home extends PureComponent {
  handleResize = _.throttle(() => {
    this.setState({ isMobile: window.innerWidth < SCREEN_SIZES.TABLET_SMALL });
  }, 100);

  constructor(props) {
    const {
      rootMargin,
      threshold: { mainTitleSemiVisible, mainTitleFullyVisible },
    } = observerOptions;

    super(props);
    this.homeContentRef = React.createRef();

    this.navMenu = React.createRef();
    this.titleArea = React.createRef();
    this.mainTitle = React.createRef();

    this.observer = new IntersectionObserver(this.setHeaderNegativeStyling, {
      rootMargin,
      threshold: [mainTitleSemiVisible, mainTitleFullyVisible],
    });

    this.state = {
      isMobile: window.innerWidth < SCREEN_SIZES.TABLET_SMALL,
      scrolled: false,
    };
  }

  componentDidMount() {
    const { changePermition, analyticsCustom, analyticsProduct } = this.props;
    this.setState({ isMobile: window.innerWidth < SCREEN_SIZES.TABLET_SMALL });
    window.addEventListener('resize', this.handleResize);
    this.createObserver(this.mainTitle);
    const analyticsPageLoad = {
      ...analytics,
      product: analyticsProduct,
      custom: analyticsCustom,
    };
    store.dispatch(AppActions.pageLoad(analyticsPageLoad));

    changePermition({
      route: 'acquisition',
      permition: true,
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  getCardAcquisitionPagePath = () => {
    let queryString = removeQueryParamsFromUrl(['cartao', 'id', 'dn']);
    queryString = decodeURIComponent(queryString);
    const paramKeysArray = getAllQueryParamKeys(window.location.search);
    if (paramKeysArray.includes('id')) {
      return `${CARD_ACQUISITION}?${queryString}`;
    }
    if (paramKeysArray.includes('cartao')) {
      return `${CARD_COD_CARD_ACQUISITION}?${queryString}`;
    }
    return null;
  };

  createObserver = observedSection => {
    this.observer.observe(observedSection.current);
  };

  setHeaderNegativeStyling = entries => {
    const {
      threshold: { mainTitleSemiVisible, mainTitleFullyVisible },
    } = observerOptions;

    entries.forEach(entry => {
      if (entry.intersectionRatio <= mainTitleSemiVisible) {
        if (this.navMenu.current) {
          this.navMenu.current.classList.add('scrolled');
          this.setState({ scrolled: true });
        }
      } else if (entry.intersectionRatio >= mainTitleFullyVisible) {
        if (this.navMenu.current) {
          this.navMenu.current.classList.remove('scrolled');
          this.setState({ scrolled: false });
        }
      }
    });
  };

  replaceMainTitle = (title, name) => {
    if (name === '') name = 'o seu amigo';

    // eslint-disable-next-line no-template-curly-in-string
    return title.replace('${customer.name}', `${name}`);
  };

  getContentArray = (content, params) => {
    const { filter, contentTypes } = params;
    return whiteLabelContentsObjectStructurer(content, filter, contentTypes);
  };

  handleButton = () => {
    const { history } = this.props;
    store.dispatch(AppActions.customLink(analyticsClick));
    history.push(this.getCardAcquisitionPagePath());
  };

  handleReference = ref => {
    switch (ref) {
      case 'cartao':
        return `#${NAV_MENU.PAGEINTRO_ANCHOR}`;
      case 'vantagens':
        return `#${NAV_MENU.BENEFIT_ANCHOR}`;
      case 'facilidades':
        return `#${NAV_MENU.FACILITIES_ANCHOR}`;
      case 'transparencia':
        return `#${NAV_MENU.PRODUCTDETAIL_ANCHOR}`;
      default:
        return '#';
    }
  };

  render() {
    const {
      whitelabelContent,
      images,
      texts,
      links,
      icons,
      firstName,
    } = this.props;
    const { isMobile, scrolled } = this.state;

    // TODO: Trazer outros arrayContents da Landing Page para essa realidade de constantes.

    const dnDetailListContent = this.getContentArray(
      whitelabelContent,
      dnDetailListParams
    );

    const collapsibleContent = this.getContentArray(
      whitelabelContent,
      collapsibleListParams
    );

    const naviMenuAnchors = this.getContentArray(
      whitelabelContent,
      navMenuParams
    );

    return (
      <div className="home-wrapper-whitelabel">
        <a href={`#navMenu_${idComponentContext}`} className="skip">
          Ir para menu
        </a>
        <a href={`#titleWhiteLabel_${idComponentContext}`} className="skip">
          Ir para conteúdo principal
        </a>
        <a href={`#footer_${idComponentContext}`} className="skip">
          Ir para rodapé
        </a>
        <div className="home-scroll">
          <DynamicBackground
            className="dynamic-background-component"
            imageSrc={getBackgroundImg(
              images['main-background-landing-image'],
              isMobile
            )}
          >
            <DefaultTemplate idComponentContext={idComponentContext}>
              <NavMenu
                idComponentContext={idComponentContext}
                navLogo={images['main-application-logo-positive-image'].url}
                navLogoNegative={
                  images['main-application-logo-negative-image'].url
                }
                navLogoAlt={images['main-application-logo-positive-image'].alt}
                scrolled={scrolled}
                navLinks={this.getContentArray(
                  whitelabelContent,
                  navMenuParams
                )}
                componentRef={this.navMenu}
                reference={e => this.handleReference(e)}
              />

              <header role="banner">
                <WebPageHeader
                  idComponentContext={idComponentContext}
                  alt={images['main-application-logo-positive-image'].alt}
                  logo={images['main-application-logo-positive-image'].url}
                />

                <div ref={this.titleArea}>
                  <Title
                    reference={this.mainTitle}
                    idComponentContext={idComponentContext}
                    mainTitle={this.replaceMainTitle(
                      texts['main-landing-title'].text,
                      firstName
                    )}
                  />
                </div>
              </header>

              <Card>
                {/* // TODO alterar renderização do card da imagem no credicard zero após apartar os repo */}
                <main role="main">
                  <PageIntro
                    idComponentContext={idComponentContext}
                    introTitle={texts['pageintro-landing-title'].text}
                    introImage={images['main-landing-card-image'].url}
                    introDescriptions={this.getContentArray(
                      whitelabelContent,
                      pageIntroParams
                    )}
                    btnTitle={links['main-landing-button-acquistion'].text}
                    btnClick={this.handleButton}
                    anchor={naviMenuAnchors[0].section.url}
                  />

                  <BenefitsList
                    idComponentContext={idComponentContext}
                    titleContent={texts['benefitslist-landing-title'].text}
                    isMobile={isMobile}
                    listContent={this.getContentArray(
                      whitelabelContent,
                      benefitsListParams
                    )}
                    anchor={naviMenuAnchors[1].section.url}
                  />

                  <ProductDetailList
                    idComponentContext={idComponentContext}
                    listTitle={texts['carddetaillist-landing-title'].text}
                    listContent={this.getContentArray(
                      whitelabelContent,
                      cardDetailParams
                    )}
                    anchor={naviMenuAnchors[2].section.url}
                  />

                  {(dnDetailListContent.length > 0 ||
                    collapsibleContent.length > 0) && (
                    <DnDetailList
                      idComponentContext={idComponentContext}
                      listTitle={texts['dndetaillist-landing-title'].text}
                      listContent={dnDetailListContent}
                      btnClick={this.handleButton}
                    />
                  )}

                  {collapsibleContent.length > 0 && (
                    <CollapsibleList
                      idComponentContext={idComponentContext}
                      listIcon={
                        icons['dndetailitem-collapsible-landing-icon'].name
                      }
                      isMobile={isMobile}
                      listContent={collapsibleContent}
                    />
                  )}
                </main>

                <Footer
                  idComponentContext={idComponentContext}
                  companyName={texts['footer-landing-company-name'].text}
                  companyAddress={texts['footer-landing-company-address'].text}
                />
              </Card>
            </DefaultTemplate>
          </DynamicBackground>
        </div>
      </div>
    );
  }
}

Home.propTypes = homePropTypes;

const mapStateToProps = state => ({
  analyticsProduct: state.analytics.product,
  analyticsCustom: state.analytics.custom,
  whitelabelContent: state.whiteLabel,
  firstName: state.share.shareInfo.firstName,
  cardDn: state.whiteLabel.dn,
  images: state.whiteLabel.images,
  texts: state.whiteLabel.texts,
  links: state.whiteLabel.links,
  icons: state.whiteLabel.icons,
  analytics: state.analytics,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...AppActions,
      ...ShareActions,
      ...WhiteLabelActions,
      ...RoutesControlActions,
    },
    dispatch
  );

const HomeWithRouter = withRouter(Home);

export default connect(mapStateToProps, mapDispatchToProps)(HomeWithRouter);
