import React from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import CartaoLuizaLoading from './assets/cartaoluiza-loading.svg';
import DefaultLoading from './assets/default-loading.svg';
import HipercardLoading from './assets/hipercard-loading.svg';
import ItaucardLoading from './assets/itaucard-loading.svg';

import './_Loading.scss';

function Loading({ loadPrimary }) {
  const { theme } = useSelector(state => state.app);

  function handleThemeLoading() {
    switch (theme) {
      case 'hipercard-theme-default':
        return HipercardLoading;
      case 'cartaoluiza-theme-default':
        return CartaoLuizaLoading;
      case 'itaucard-theme-default':
        return ItaucardLoading;
      default:
        return DefaultLoading;
    }
  }

  return (
    <img
      className={`itau-global-loading ${
        loadPrimary ? 'itau-load-primary' : 'itau-load-secondary'
      }`}
      src={handleThemeLoading()}
      alt="Carregando"
    />
  );
}

Loading.propTypes = {
  loadPrimary: PropTypes.bool,
};

Loading.defaultProps = {
  loadPrimary: true,
};

export default Loading;
