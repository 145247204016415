import React, { Component } from 'react';
import { connect } from 'react-redux';

import './_DefaultError.scss';
import Clipboard from 'clipboard';

import ActionButton from '../../components/ActionButton/ActionButton';
import constants from '../../constants';
import store from '../../store';
import { Creators as AppActions } from '../../store/ducks/app';
import { analyticsPage } from './DefaultErrorPageTag';
import defaultErrorPropTypes from './defaultErrorPropTypes';
import ErrorContent from './ErrorContent/ErrorContent';
import ErrorHeader from './ErrorHeader/ErrorHeader';

const idComponentContext = 'pageErro';
const backButtonIndex = 0;
const {
  RESOURCES: {
    HEADER: { HEADER_TITLE },
    ERROR_PAGE: {
      CONTENT_TITLE,
      CONTENT_DESCRIPTION,
      CONTENT_LINK,
      CONTENT_LINK_SECOND_RETRY,
      CONTENT_LINK_NO_RETRY,
      CONTENT_BUTTON,
      IE,
    },
  },
} = constants;

class DefaultError extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const {
      whereErrorOccurred,
      analyticsProduct,
      analyticsCustom,
    } = this.props;

    const buttonIdSelector = `#btnAction_${idComponentContext}_${backButtonIndex}`;
    this.clipboard = new Clipboard(buttonIdSelector);

    store.dispatch(
      AppActions.pageLoad(
        analyticsPage(whereErrorOccurred),
        analyticsProduct,
        analyticsCustom
      )
    );
  }

  // eslint-disable-next-line class-methods-use-this
  getLocationUrl() {
    return window.location.href;
  }

  getLinkContent = () => {
    const { retryCount } = this.props;
    if (retryCount <= 0) return CONTENT_LINK;
    return CONTENT_LINK_SECOND_RETRY;
  };

  render() {
    const {
      retry,
      retryAction,
      backAction,
      removeButton,
      isBrowserIe,
    } = this.props;

    return (
      <div className="default-error">
        <ErrorHeader title={HEADER_TITLE} />
        <div className="default-error-wrapper">
          {isBrowserIe && (
            <ErrorContent
              title={IE.CONTENT_TITLE}
              description={IE.CONTENT_DESCRIPTION}
              descriptionList={IE.CONTENT_DESCRIPTION_LIST}
            />
          )}
          {!isBrowserIe && (
            <ErrorContent
              title={CONTENT_TITLE}
              description={CONTENT_DESCRIPTION}
              link={this.getLinkContent()}
              linkNoRetry={CONTENT_LINK_NO_RETRY}
              retry={retry}
              retryAction={retryAction}
            />
          )}

          <div className="error-footer-block">
            {(!removeButton || isBrowserIe) && (
              <ActionButton
                btnTitle={isBrowserIe ? IE.CONTENT_BUTTON : CONTENT_BUTTON}
                idComponentContext={idComponentContext}
                onClick={() => backAction()}
                index={backButtonIndex}
                dataClipBoardText={this.getLocationUrl()}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

DefaultError.propTypes = defaultErrorPropTypes;

DefaultError.defaultProps = {
  removeButton: false,
  isBrowserIe: false,
  backAction: () => {},
  analyticsCustom: {},
  analyticsProduct: {},
};

const mapStateToProps = state => ({
  whereErrorOccurred: state.error.whereErrorOccurred,
  analyticsProduct: state.analytics.product,
  analyticsCustom: state.analytics.custom,
});

export default connect(mapStateToProps)(DefaultError);
