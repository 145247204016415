import React from 'react';

import './_DnDetailItem.scss';
import boldTagTitleReplace from '../../../../util/htmlTagHandlers/boldTagTitleReplace';
import parseSanitizedHtml from '../../../../util/parseSanitizedHtml';
import dnDetailItemPropTypes from './dnDetailItemPropTypes';

const DnDetailItem = ({ description, index }) => {
  const headerTaggedDescription = boldTagTitleReplace(description, 'h3');

  return (
    <div className="DnDetailItem">
      <div className="dndetailitem-wrapper">
        {description && (
          <div className="dndetail-description" id={`dn-detail-item-${index}`}>
            {parseSanitizedHtml(headerTaggedDescription)}
          </div>
        )}
      </div>
    </div>
  );
};

DnDetailItem.propTypes = dnDetailItemPropTypes;

DnDetailItem.defaultProps = {
  description: '',
};

export default DnDetailItem;
