const analytics = {
  site: {
    nome: 'IC',
    ambiente: 'NL',
    negocio: 'NCC',
    tipoDeCanal: 'Web',
  },
  cartao: { nome: 'Itaucard' },
  page: {
    secao: 'MemberGetMember',
    subSecao1: 'CartaoIndicadoPorAmigo',
    url: 'www.indique.itau.com.br',
    nome: 'IC:NL:NCC:MemberGetMember:CartaoIndicadoPorAmigo:Itaucard',
    templateName: 'home',
  },
  rule: 'pageLoad',
};

const analyticsClick = 'BTN:NCC:MemberGetMember:PecaJaOSeu:RP:CP';
const pageName = 'Home';

export { analytics, analyticsClick, pageName };
