import PropTypes from 'prop-types';

export default {
  idComponentContext: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  itemIcon: PropTypes.string.isRequired,
  itemTitle: PropTypes.string.isRequired,
  itemText: PropTypes.string.isRequired,
  itemLink: PropTypes.string,
  itemUrl: PropTypes.string,
};
