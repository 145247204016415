import ERROR_PAGE from './ERROR_PAGE';
import ERROR_PAGE_REDIRECT from './ERROR_PAGE_REDIRECT';
import HEADER from './HEADER';
import NAV_MENU from './NAV_MENU';
import PAGINATION_BUTTON from './PAGINATION_BUTTON';

const RESOURCES = {
  PAGINATION_BUTTON,
  ERROR_PAGE,
  ERROR_PAGE_REDIRECT,
  HEADER,
  NAV_MENU,
};

export default RESOURCES;
