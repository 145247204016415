import PropTypes from 'prop-types';

export default {
  retry: PropTypes.bool.isRequired,
  retryAction: PropTypes.func.isRequired,
  backAction: PropTypes.func,
  removeButton: PropTypes.bool,
  isBrowserIe: PropTypes.bool,
  retryCount: PropTypes.number.isRequired,
  whereErrorOccurred: PropTypes.string.isRequired,
  analyticsCustom: PropTypes.shape(),
  analyticsProduct: PropTypes.shape(),
};
