import shared from './shared';

const quality = {
  ENVIRONMENT: 'quality',
  HAS_EXPERIMENTS: false,
  TURN_GOOGLE_ANALYTICS_ON: true,
  GA_FRAMEWORK_DA__LIBS_URL:
    'https://www.itau.com.br/_arquivosestaticos/Itau/defaultTheme/js/da/framework/importLibJSFrameworkDA.js?T34Z5CT',
  GA_FRAMEWORK_DA_URL:
    'https://www.itau.com.br/_arquivosestaticos/Itau/defaultTheme/js/da/framework/frameworkDA.js?T34Z5CT',
  GATEWAY_HOST: 'https://cartoes-qa.staging.gateway.zup.me/',
  GATEWAY_APP_KEY: '96f74290906901375d0a000d3ac06d76',
  MGM_AUTH: 'aXRhdS1tZ206bWdtOTEwMg==',
  DEBUG_MODE: true,
  PUBLIC_URL: 'https://itau-mgm-indicated-itaucard-qa.continuousplatform.com/',
  GOOGLE_CHAT_API_URL:
    'https://chat.googleapis.com/v1/spaces/AAAA5K8oQgI/messages?key=AIzaSyDdI0hCZtE6vySjMm-WEfRq3CPzqKqqsHI&token=os0Mv6Qt8K1rUfCQw3bKodCA0zv1eaCZE9V6lU_xQTY%3D',
  GOOGLE_CHAT_API_INVALID_URL_PARAMS:
    'https://chat.googleapis.com/v1/spaces/AAAAHDZ89j0/messages?key=AIzaSyDdI0hCZtE6vySjMm-WEfRq3CPzqKqqsHI&token=xL3ZzZYGwo54lVNeM7JJXcaeBnYfNWwcxObgGuQaMDw%3D',
};

export default {
  ...shared,
  ...quality,
};
