import PropTypes from 'prop-types';

export default {
  idComponentContext: PropTypes.string.isRequired,
  introTitle: PropTypes.string.isRequired,
  introImage: PropTypes.string.isRequired,
  introDescriptions: PropTypes.arrayOf(
    PropTypes.shape({
      // TODO: Definir estes Proptypes
    })
  ).isRequired,
  introImageAlt: PropTypes.string,
  introBackImage: PropTypes.string,
  btnTitle: PropTypes.string.isRequired,
  btnClick: PropTypes.func.isRequired,
};
