export const Types = {
  APP_INIT: 'app/init',
  APP_INIT_BY_CARDCODE: 'app/initByCardCode',
  APP_FINISH_LOADING: 'app/finishLoading',
  SET_THEME: 'app/setTheme',
  SET_BROWSER: 'app/SET_BROWSER',
  PAGE_LOAD: 'redirect/pageLoad',
  CUSTOM_LINK: 'redirect/customLink',
};

export const INITIAL_STATE = {
  loading: true,
  theme: '',
  browser: '',
};

export default function appReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.APP_INIT:
      return {
        ...state,
        loading: true,
      };

    case Types.APP_INIT_BY_CARDCODE:
      return {
        ...state,
        loading: true,
      };

    case Types.APP_FINISH_LOADING:
      return {
        ...state,
        loading: false,
      };

    case Types.SET_THEME:
      return {
        ...state,
        theme: action.payload.theme,
      };

    case Types.SET_BROWSER:
      return {
        ...state,
        browser: action.payload.browser,
      };

    case Types.PAGE_LOAD:
      return {
        ...state,
        analytics: action.analytics,
      };

    case Types.CUSTOM_LINK:
      return {
        ...state,
        itemClicado: action.itemClicado,
      };

    default:
      return state;
  }
}

export const Creators = {
  appInit: () => ({
    type: Types.APP_INIT,
  }),

  appInitByCardCode: () => ({
    type: Types.APP_INIT_BY_CARDCODE,
  }),

  appFinishLoading: () => ({
    type: Types.APP_FINISH_LOADING,
  }),

  setTheme: theme => ({
    type: Types.SET_THEME,
    payload: { theme },
  }),

  setBrowser: browser => ({
    type: Types.SET_BROWSER,
    payload: { browser },
  }),

  pageLoad: analytics => ({
    type: Types.PAGE_LOAD,
    analytics,
  }),

  customLink: itemClicado => ({
    type: Types.CUSTOM_LINK,
    itemClicado,
  }),
};
