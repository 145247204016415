import PropTypes from 'prop-types';

export default {
  title: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  linkUrl: PropTypes.string.isRequired,
  linkAria: PropTypes.string.isRequired,
  idComponentContext: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};
