import { put } from 'redux-saga/effects';

import { Creators as AppActions } from '../../../ducks/app';
import { Creators as ErrorActions } from '../../../ducks/error';

const { appFinishLoading, pageLoad } = AppActions;
const { setErrorConditions } = ErrorActions;

function getRedirectErrorPayload(errorName) {
  return {
    site: {
      nome: 'IC',
      ambiente: 'NL',
      negocio: 'NCC',
      tipoDeCanal: 'Web',
    },
    page: {
      secao: 'MemberGetMember',
      subSecao1: 'CartaoIndicadoPorAmigo',
      url: 'https://www.indique.itau.com.br',
      nome: 'IC:NL:NCC:MemberGetMember:CartaoIndicadoPorAmigo:Erro',
    },
    custom: {
      nomeDoErro: errorName,
      events: ['erro'],
    },
    rule: 'pageLoad',
  };
}

export default function* redirectErrorHandler(whereErrorOccurred) {
  const errorStatus = '';
  const endpointUrl = '';
  const hasCriticalError = false;

  const errorConditionsObject = {
    errorStatus,
    whereErrorOccurred,
    endpointUrl,
    hasCriticalError,
  };
  const analytics = getRedirectErrorPayload(whereErrorOccurred);
  yield put(setErrorConditions(errorConditionsObject));
  yield put(pageLoad(analytics));
  yield put(appFinishLoading());
}
