import PropTypes from 'prop-types';

export default {
  idComponentContext: PropTypes.string.isRequired,
  listTitle: PropTypes.string.isRequired,
  btnClick: PropTypes.func.isRequired,
  listContent: PropTypes.arrayOf(
    PropTypes.shape({
      // TODO: Definir estes Proptypes
    })
  ),
};
