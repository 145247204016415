import PropTypes from 'prop-types';

export default {
  idComponentContext: PropTypes.string.isRequired,
  listContent: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.shape({
        text: PropTypes.string.isRequired,
      }).isRequired,
      description: PropTypes.shape({
        text: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  listIcon: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
};
