import React from 'react';

import ActionButton from '../../../components/ActionButton/ActionButton';
import DnDetailItem from './DnDetailItem/DnDetailItem';
import dnDetailListPropTypes from './dnDetailListPropTypes';
import './_DnDetailList.scss';

const idComponent = 'DnDetailList';

const DnDetailList = ({
  idComponentContext,
  listTitle,
  listContent,
  btnClick,
}) => (
  <section className="DnDetailList">
    <div
      className="dndetailist-wrapper"
      id={`${idComponent}_${idComponentContext}`}
    >
      <div className="container">
        <h2
          className="title-list"
          id={`lblTitle_${idComponent}_${idComponentContext}`}
        >
          {listTitle}
        </h2>
        <ul className="content-list">
          {listContent &&
            listContent.map((contentItem, index) => (
              <li
                key={contentItem.description.text}
                className="content-item"
                id={`lstItem${index}_${idComponent}_${idComponentContext}`}
                aria-describedby={`dn-detail-item-mark-${index} dn-detail-item-${index}`}
              >
                <DnDetailItem
                  description={contentItem.description.text}
                  index={index}
                />
              </li>
            ))}
        </ul>

        <div className="extra-button">
          <ActionButton
            idComponentContext={idComponentContext}
            index={1}
            btnTitle="peça já o seu"
            onClick={btnClick}
          />
        </div>
      </div>
    </div>
  </section>
);

DnDetailList.propTypes = dnDetailListPropTypes;

DnDetailList.defaultProps = {
  listContent: [],
};

export default DnDetailList;
