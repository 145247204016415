import React from 'react';

import Collapsible from '../../../components/Collapsible/Collapsible';
import './_CollapsibleList.scss';
import collapsibleListPropTypes from './collapsibleListPropTypes';

const idComponent = 'collapsibleList';

const CollapsibleList = ({
  idComponentContext,
  listIcon,
  listContent,
  isMobile,
}) => (
  <section
    id={`${idComponent}_${idComponentContext}`}
    className={`collapsiblelist-wrapper ${isMobile ? '' : 'container'}`}
  >
    <ul className="collapsiblelist-items">
      {listContent.map((item, index) => (
        <li key={item.title.text}>
          <Collapsible
            idComponentContext={idComponentContext}
            index={index}
            icon={listIcon}
            title={item.title.text}
            content={item.description.text}
          />
        </li>
      ))}
    </ul>
  </section>
);

CollapsibleList.propTypes = collapsibleListPropTypes;

export default CollapsibleList;
