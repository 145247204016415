import React, { Component } from 'react';
import { connect } from 'react-redux';

import { pageLoad } from '../../analytics';
import constants from '../../constants';
import { analyticsPage } from './DefaultErrorPageTag';
import defaultErrorPropTypes from './defaultErrorPropTypes';
import ErrorContent from './ErrorContentRedirect/ErrorContentRedirect';
import './_DefaultErrorRedirect.scss';

const {
  RESOURCES: {
    ERROR_PAGE_REDIRECT: {
      CONTENT_TITLE,
      CONTENT_DESCRIPTION,
      CONTENT_LINK,
      CONTENT_TITLE_SECOND_RETRY,
      CONTENT_LINK_SECOND_RETRY,
      CONTENT_LINK_NO_RETRY,
      IE,
    },
  },
} = constants;

export class DefaultErrorRedirect extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const {
      whereErrorOccurred,
      analyticsProduct,
      analyticsCustom,
    } = this.props;

    pageLoad({
      page: analyticsPage(whereErrorOccurred),
      product: analyticsProduct,
      custom: analyticsCustom,
    });
  }

  getLinkContent = () => {
    const { retryCount } = this.props;
    if (retryCount <= 0) return CONTENT_LINK;
    return CONTENT_LINK_SECOND_RETRY;
  };

  getContentTitle = () => {
    const { retryCount } = this.props;
    if (retryCount <= 0) {
      return CONTENT_TITLE;
    }
    return CONTENT_TITLE_SECOND_RETRY;
  };

  render() {
    const { retry, retryAction, isBrowserIe } = this.props;

    return (
      <div className="default-error-redirect">
        <div className="default-error-wrapper-redirect">
          {isBrowserIe && (
            <ErrorContent
              title={IE.CONTENT_TITLE}
              description={IE.CONTENT_DESCRIPTION}
              descriptionList={IE.CONTENT_DESCRIPTION_LIST}
            />
          )}
          {!isBrowserIe && (
            <ErrorContent
              title={this.getContentTitle()}
              description={CONTENT_DESCRIPTION}
              linkNoRetry={CONTENT_LINK_NO_RETRY}
              link={this.getLinkContent()}
              retry={retry}
              retryAction={retryAction}
            />
          )}
        </div>
      </div>
    );
  }
}

DefaultErrorRedirect.propTypes = defaultErrorPropTypes;

DefaultErrorRedirect.defaultProps = {
  removeButton: false,
  isBrowserIe: false,
  backAction: () => {},
  analyticsCustom: {},
  analyticsProduct: {},
};

const mapStateToProps = state => ({
  whereErrorOccurred: state.error.whereErrorOccurred,
  analyticsProduct: state.analytics.product,
  analyticsCustom: state.analytics.custom,
});

export default connect(mapStateToProps)(DefaultErrorRedirect);
