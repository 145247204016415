import api from './config/api';
import environmentVariables from './config/environmentVariables';
import { sendMessage } from './endpoints/googleChatMessage';
import { getQueryParam } from './util';

const head = document.getElementsByTagName('head')[0];
const dnHipercard = '1159';
const dnLuizacard = '1386';
const dnMagalu = '1299';
const cardCodHipercard = '4';
const cardCodLuizacard = '147';
const cardCodMagalu = '185';

const scriptZupDataLayerConfig = `
  window.dataLayer = window.dataLayer || [];
  function gtag() { dataLayer.push(arguments); }
  gtag('js', new Date());

  gtag('config', 'UA-161576556-2',
    {
      'optimize_id': 'GTM-M6LJ43K',
      'custom_map': {
        'dimension1': 'tag_name',
        'dimension2': 'environment',
        'dimension3': 'application',
        'dimension4': 'brand',
        'dimension5': 'page_name',
        'dimension6': 'dn',
        'dimension7': 'customer_id',
      }
    }
  );
`;
const scriptAntiFlicker = `
  (function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;
  h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};
  (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;
  })(window,document.documentElement,'async-hide','dataLayer',4000,
  {'GTM-M6LJ43K':true});
`;
const styleAntiFlicker = `
  .async-hide { opacity: 0 !important}
`;

const pageFetch = async url => {
  const { MGM_AUTH, GOOGLE_CHAT_API_URL } = environmentVariables;
  const config = {
    headers: { Authorization: `Basic ${MGM_AUTH}` },
  };

  try {
    const response = await api.get(url, config);
    return response.data;
  } catch (error) {
    sendMessage(error, 'Favicon indicado', GOOGLE_CHAT_API_URL);
    return error;
  }
};

const replaceDnByCardCod = (dn, cardCod) => {
  if (cardCod !== null && cardCod === cardCodLuizacard) {
    return dnLuizacard;
  }
  if (cardCod !== null && cardCod === cardCodMagalu) {
    return dnMagalu;
  }
  if (cardCod !== null && cardCod === cardCodHipercard) {
    return dnHipercard;
  }
  return dn;
};

const isHiperOrLuiza = dn => {
  return dn === dnLuizacard || dn === dnHipercard || dn === dnMagalu;
};

const configure_page_title_bar = async () => {
  let pageFetchResponse = {};
  const elementPageTitle = document.createElement('title');
  const elementPageFavicon = document.createElement('link');

  let dn = getQueryParam('dn');
  const cardCod = getQueryParam('cartao');
  dn = replaceDnByCardCod(dn, cardCod);

  if (isHiperOrLuiza(dn)) {
    const { GATEWAY_APP_KEY, GATEWAY_HOST } = environmentVariables;

    pageFetchResponse = await pageFetch(
      `${GATEWAY_HOST}mgm-dn/v1/indicado/favicon?dn=${dn}&gw-app-key=${GATEWAY_APP_KEY}`
    );
  }
  if (pageFetchResponse.titulo && pageFetchResponse.favicon) {
    elementPageTitle.innerHTML = pageFetchResponse.titulo;
    elementPageFavicon.setAttribute('rel', 'shortcut icon');
    elementPageFavicon.setAttribute('href', pageFetchResponse.favicon);
  } else {
    elementPageTitle.innerHTML = 'Itaú - Cartão de Crédito';
    elementPageFavicon.setAttribute('rel', 'shortcut icon');
    elementPageFavicon.setAttribute(
      'href',
      '/preview_icons/itaucard-favicon.png'
    );
  }

  await head.appendChild(elementPageTitle);
  await head.appendChild(elementPageFavicon);
};

const configure_optimize = async () => {
  const elementZupDataLayerConfig = document.createElement('script');
  const elementAntiFlickerStyle = document.createElement('style');
  const elementAntiFlickerScript = document.createElement('script');

  elementZupDataLayerConfig.innerHTML = scriptZupDataLayerConfig;
  elementZupDataLayerConfig.type = 'text/javascript';

  elementAntiFlickerScript.innerHTML = scriptAntiFlicker;
  elementAntiFlickerScript.type = 'text/javascript';

  elementAntiFlickerStyle.innerHTML = styleAntiFlicker;
  elementAntiFlickerStyle.type = 'text/css';

  await head.appendChild(elementZupDataLayerConfig);
  await head.appendChild(elementAntiFlickerScript);
  await head.appendChild(elementAntiFlickerStyle);
};

const configure = async () => {
  const { HAS_EXPERIMENTS } = environmentVariables;

  await configure_page_title_bar();
  if (HAS_EXPERIMENTS) await configure_optimize();
};

export default configure;
