import React from 'react';

import PropTypes from 'prop-types';
import './_DefaultTemplate.scss';

const DefaultTemplate = ({ idComponentContext, children }) => (
  <div
    id={`${idComponentContext}_defaultTemplate`}
    className="default-template-whitelabel"
  >
    {children}
  </div>
);

DefaultTemplate.propTypes = {
  idComponentContext: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default DefaultTemplate;
