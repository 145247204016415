import React from 'react';

import titlePropTypes from './titlePropTypes';
import './_Title.scss';

const idComponent = 'titleWhiteLabel';

const Title = ({ idComponentContext, mainTitle, reference }) => (
  <div
    className="titlewhitelabel-wrapper"
    id={`${idComponent}_${idComponentContext}`}
  >
    <div className="container">
      <h1
        className="main-title"
        id={`lblTitle_${idComponent}_${idComponentContext}`}
        ref={reference}
      >
        {mainTitle}
      </h1>
    </div>
  </div>
);

Title.propTypes = titlePropTypes;

Title.defaultProps = {
  reference: () => {},
};

export default Title;
