/* eslint-disable no-console */
import { put } from 'redux-saga/effects';

// import environmentVariables from '../config/environmentVariables';
import constants from '../constants';
import { Creators as ErrorActions } from '../store/ducks/error';

const {
  ERROR_TYPES: {
    ANALYTICS: { ITAU_TRACK },
  },
} = constants;

// const { DEBUG_MODE, TURN_GOOGLE_ANALYTICS_ON } = environmentVariables;

// const isGoogleAnalyticsOn = TURN_GOOGLE_ANALYTICS_ON;
// const isDebugModeOn = DEBUG_MODE;
const paramToBreakItauTracking = 'break-itau-track';

function breakAnalytics(paramToVerify) {
  const params = new URLSearchParams(window.location.search);
  const needToBreakAnalytics = params.get(paramToVerify) === 'true';
  if (needToBreakAnalytics) return true;
  return false;
}

export function* track(data) {
  try {
    if (breakAnalytics(paramToBreakItauTracking)) throw new Error();
    window.analyticsData = data;
    //    if (isDebugModeOn) console.log(data.rule, data);
    //    if (isGoogleAnalyticsOn) window._frameworkDA.Track();
  } catch (error) {
    yield put(ErrorActions.callErrorHandler(error, ITAU_TRACK));
  }
}
