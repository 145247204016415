import React, { Component } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import Loading from '../components/Loading/Loading';
import environmentVariables from '../config/environmentVariables';
import constants from '../constants';
import DefaultError from '../pages/DefaultError/DefaultError';
import Home from '../pages/Home/Home';
import { Creators as AppActions } from '../store/ducks/app';
import { Creators as ErrorActions } from '../store/ducks/error';
import { Creators as ShareActions } from '../store/ducks/share';
import { getQueryParam } from '../util';
import getBrowser from '../util/getBrowser';
import verifyMainPath from '../util/verifyMainPath';
import './_Main.scss';

const {
  BUSINESS_RULES: { MAX_MANUAL_RETRY },
} = constants;
const isBrowserIe = getBrowser() === 'ie';

class App extends Component {
  componentDidMount() {
    const { setBrowser, setId } = this.props;
    const { DEBUG_MODE, ENVIRONMENT } = environmentVariables;

    // eslint-disable-next-line no-console
    if (DEBUG_MODE) console.log('Ambiente: ', ENVIRONMENT);
    setId(getQueryParam('id'));
    setBrowser(getBrowser());
  }

  showRetryActionButton = () => {
    const { errorRetryCount } = this.props;

    return errorRetryCount < MAX_MANUAL_RETRY;
  };

  render() {
    const {
      theme,
      loading,
      appHasCriticalError,
      errorRetryCount,
      tryAgain,
    } = this.props;

    if (!loading) {
      if (theme !== '') {
        require(`../styles/themes/${theme}.css`);
      } else {
        require('../styles/themes/itaucard-theme-default.css');
      }
    }

    if (loading) {
      return (
        <div className="itau-loading-wrapper">
          <Loading loadPrimary={false} />
        </div>
      );
    }

    if (appHasCriticalError || isBrowserIe) {
      return (
        <div className="App itaucard-theme-default">
          <DefaultError
            retry={this.showRetryActionButton()}
            retryCount={errorRetryCount}
            retryAction={tryAgain}
            removeButton={!verifyMainPath('whitelabel')}
            isBrowserIe={isBrowserIe}
          />
        </div>
      );
    }

    return (
      <div className={`App ${theme}`}>
        <Home />
      </div>
    );
  }
}

App.propTypes = {
  setBrowser: PropTypes.func.isRequired,
  tryAgain: PropTypes.func.isRequired,
  errorRetryCount: PropTypes.number.isRequired,
  appHasCriticalError: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  theme: PropTypes.string.isRequired,
  setId: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  appHasCriticalError: state.error.hasCriticalError,
  whereErrorOccurred: state.error.whereErrorOccurred,
  errorRetryCount: state.error.manualRetryCount,
  appInit: state.app.appInit,
  loading: state.app.loading,
  theme: state.app.theme,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...AppActions,
      ...ErrorActions,
      ...ShareActions,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(App);
