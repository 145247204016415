export const Types = {
  WHITE_LABEL_REQUEST: 'whitelabel/request',
  SET_DATA: 'whitelabel/setData',
};

export const INITIAL_STATE = {
  brand: '',
  cardDescription: '',
  cardCategory: '',
  texts: {},
  icons: {},
  images: {},
  links: {},
};

export default function whiteLabelReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.WHITE_LABEL_REQUEST:
      return {
        ...state,
      };
    case Types.SET_DATA:
      return {
        ...state,
        brand: action.payload.whitelabelData.brand,
        cardDescription: action.payload.whitelabelData.cardDescription,
        cardCategory: action.payload.whitelabelData.cardCategory,
        portfolio: action.payload.whitelabelData.portfolio,
        variant: action.payload.whitelabelData.variant,
        minimumIncome: action.payload.whitelabelData.minimumIncome,
        sku: action.payload.whitelabelData.sku,
        dn: action.payload.whitelabelData.dn,
        name: action.payload.whitelabelData.name,
        texts: action.payload.whitelabelData.texts,
        icons: action.payload.whitelabelData.icons,
        images: action.payload.whitelabelData.images,
        links: action.payload.whitelabelData.links,
      };

    default:
      return state;
  }
}

export const Creators = {
  whitelabelRequest: () => ({
    type: Types.WHITE_LABEL_REQUEST,
  }),

  setData: whitelabelData => ({
    type: Types.SET_DATA,
    payload: { whitelabelData },
  }),
};
