export const Types = {
  ADD_TO_MANUAL_RETRY: 'error/ADD_TO_MANUAL_RETRY',
  SET_ERROR_CONDITIONS: 'error/SET_ERROR_CONDITIONS',
  CLEAN_ERROR_CONDITIONS_AND_RETRY_COUNTS:
    'error/CLEAN_ERROR_CONDITIONS_AND_RETRY_COUNTS',

  TRY_AGAIN: 'error/TRY_AGAIN',

  CALL_ERROR_HANDLER: 'error/CALL_ERROR_HANDLER',
};

const INITIAL_STATE = {
  whereErrorOccurred: '',
  hasError: false,
  hasCriticalError: false,
  manualRetryCount: 0,
  retryDisabled: false,
};

export default function errorReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.ADD_TO_MANUAL_RETRY:
      return {
        ...state,
        manualRetryCount: state.manualRetryCount + 1,
      };

    case Types.SET_ERROR_CONDITIONS:
      return {
        ...state,
        whereErrorOccurred: action.payload.errorConditions.whereErrorOccurred,
        hasCriticalError:
          state.hasCriticalError !== true
            ? action.payload.errorConditions.hasCriticalError
            : state.hasCriticalError,
        hasError: true,
      };

    case Types.CLEAN_ERROR_CONDITIONS_AND_RETRY_COUNTS:
      return {
        ...state,
        whereErrorOccurred: '',
        hasCriticalError: false,
        hasError: false,
        manualRetryCount: 0,
      };

    default:
      return state;
  }
}

export const Creators = {
  setErrorConditions: errorConditions => ({
    type: Types.SET_ERROR_CONDITIONS,
    payload: { errorConditions },
  }),

  addOneToManualRetryCount: () => ({
    type: Types.ADD_TO_MANUAL_RETRY,
  }),

  cleanErrorConditionsAndRetryCounts: () => ({
    type: Types.CLEAN_ERROR_CONDITIONS_AND_RETRY_COUNTS,
  }),

  tryAgain: () => ({
    type: Types.TRY_AGAIN,
    payload: {},
  }),

  callErrorHandler: (error, whereErrorOccurred) => ({
    type: Types.CALL_ERROR_HANDLER,
    payload: { error, whereErrorOccurred },
  }),
};
