/* eslint-disable jsx-a11y/no-redundant-roles */
import React from 'react';

import { PropTypes } from 'prop-types';
import './_WebPageHeader.scss';

const WebPageHeader = ({ idComponentContext, logo, alt }) => (
  <div
    id={`lblHeaderWhiteLabel_${idComponentContext}`}
    className="itauheaderwhitelabel-wrapper"
  >
    <div className="container">
      {/* iOS img acessibility workaround */}
      <img className="logo" src={logo} alt={alt} role="img" />
    </div>
  </div>
);

WebPageHeader.propTypes = {
  idComponentContext: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

WebPageHeader.defaultProps = {
  alt: '',
};

export default WebPageHeader;
