/**
 * Used to change html bold tags to a
 * header tag of a specified level
 *
 * The function also removes the extra
 * unnecessary <br> tag
 *
 * @param {string} paragraphHtml
 */

const boldTagTitleReplace = (paragraphHtml, titleLevel) => {
  const boldRegex = /(<)b(>.*<\/)b(>)<br>/gm;
  return paragraphHtml.replace(boldRegex, `$1${titleLevel}$2${titleLevel}$3`);
};

export default boldTagTitleReplace;
