// import * as Sentry from '@sentry/browser';
import { put } from 'redux-saga/effects';

import constants from '../../constants';
import { Creators as AppActions } from '../ducks/app';
import { Creators as ErrorActions } from '../ducks/error';
import { addProduct, setCustom } from './analytics';
import { getShare } from './share';
import { getWhiteLabel, getWhiteLabelByCardCode } from './whiteLabel';

const {
  ERROR_TYPES: {
    FLOW: { APP_INIT_DN, APP_INIT_CARD_COD },
  },
} = constants;

export function* appInit() {
  try {
    yield getShare();
    yield getWhiteLabel();
    yield addProduct();
    yield setCustom();
    yield put(ErrorActions.cleanErrorConditionsAndRetryCounts());
    yield put(AppActions.appFinishLoading());
  } catch (error) {
    const {
      response: { status, data },
    } = error;
    if (status === 302 && data.urlRedirect) {
      window.location.replace(data.urlRedirect);
    } else {
      yield put(ErrorActions.callErrorHandler(error, APP_INIT_DN));
    }
  }
}

export function* appInitByCardCode() {
  try {
    yield getWhiteLabelByCardCode();
    yield addProduct();
    yield setCustom();

    yield put(ErrorActions.cleanErrorConditionsAndRetryCounts());
    yield put(AppActions.appFinishLoading());
  } catch (error) {
    // Sentry.captureException(error);
    yield put(ErrorActions.callErrorHandler(error, APP_INIT_CARD_COD));
  }
}
