import api from '../config/api';

const sendMessage = (error, whereErrorOccurred, googleChatApiUrl) => {
  const shouldSendMessage = process.env.REACT_APP_ENVIRONMENT !== 'test';
  if (shouldSendMessage) {
    const currentUrl = window.location.href;
    const config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };
    api.post(
      googleChatApiUrl,
      {
        text: `Ocorreu um erro na renderização na LP Itaucard: ${error} \n \n  Onde o erro ocorreu: ${whereErrorOccurred} \n \n Stack: ${error.stack} \n \n URL: ${currentUrl}`,
      },
      config
    );
  }
};

export { sendMessage };
