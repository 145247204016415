/**
 * Returns all params from window url.
 *
 * @returns {string} with all params from window url.
 */

export default function getParamsFromUrl() {
  const url = window.location.href;
  const objectUrl = new URL(url);
  const params = new URLSearchParams(objectUrl.search.slice(1));

  return params.toString();
}
