import React from 'react';

import { PropTypes } from 'prop-types';
import './_ErrorHeader.scss';

const idComponentContext = 'componentHeader';

const ErrorHeader = ({ title }) => (
  <div className="itau-global-header">
    <h1 id={`lblHeader_${idComponentContext}`} className="title">
      {title}
    </h1>
  </div>
);

ErrorHeader.propTypes = {
  title: PropTypes.string,
};

ErrorHeader.defaultProps = {
  title: 'Header',
};

export default ErrorHeader;
