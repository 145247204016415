import shared from './shared';

const production = {
  ENVIRONMENT: 'production',
  HAS_EXPERIMENTS: false,
  TURN_GOOGLE_ANALYTICS_ON: true,
  GA_FRAMEWORK_DA__LIBS_URL:
    'https://www.itau.com.br/_arquivosestaticos/Itau/defaultTheme/js/da/framework/importLibJSFrameworkDA.js?T34Z5CT',
  GA_FRAMEWORK_DA_URL:
    'https://www.itau.com.br/_arquivosestaticos/Itau/defaultTheme/js/da/framework/frameworkDA.js?T34Z5CT',
  GATEWAY_HOST: 'https://itau-cartoes-commons.gateway.zup.me/',
  GATEWAY_APP_KEY: 'b4a9cb00906901375d0d000d3ac06d76',
  MGM_AUTH: 'aXRhdS1tZ206aXRAdU1nbVp1cCM=',
  DEBUG_MODE: false,
  PUBLIC_URL: 'https://itau-mgm-indicated-itaucard.continuousplatform.com/',
  GOOGLE_CHAT_API_URL:
    'https://chat.googleapis.com/v1/spaces/AAAANJfoXMQ/messages?key=AIzaSyDdI0hCZtE6vySjMm-WEfRq3CPzqKqqsHI&token=MCFkD6qfRN-lgbk6jtzuPHGENjygAJejfOKnP8rPw9k%3D',
  GOOGLE_CHAT_API_INVALID_URL_PARAMS:
    'https://chat.googleapis.com/v1/spaces/AAAAHDZ89j0/messages?key=AIzaSyDdI0hCZtE6vySjMm-WEfRq3CPzqKqqsHI&token=xL3ZzZYGwo54lVNeM7JJXcaeBnYfNWwcxObgGuQaMDw%3D',
};

export default {
  ...shared,
  ...production,
};
