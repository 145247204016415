import { all, takeLatest } from 'redux-saga/effects';

import customLink from '../../analytics/events/customLink';
import pageLoad from '../../analytics/events/pageLoad';
import { Types as AppTypes } from '../ducks/app';
import { Types as ErrorTypes } from '../ducks/error';
import { Types as RedirectTypes } from '../ducks/redirect';
import { appInit, appInitByCardCode } from './app';
import errorHandler from './error/errorHandler';
import tryAgainHandler from './error/tryAgainHandler';
import { appInitRedirect } from './redirect';

export default function* rootSaga() {
  yield all([
    takeLatest(AppTypes.APP_INIT, appInit),
    takeLatest(AppTypes.APP_INIT_BY_CARDCODE, appInitByCardCode),
    takeLatest(AppTypes.PAGE_LOAD, pageLoad),
    takeLatest(AppTypes.CUSTOM_LINK, customLink),
    takeLatest(RedirectTypes.APP_INIT_REDIRECT, appInitRedirect),
    takeLatest(ErrorTypes.CALL_ERROR_HANDLER, errorHandler),
    takeLatest(ErrorTypes.TRY_AGAIN, tryAgainHandler),
  ]);
}
