export default {
  FLOW: {
    APP_INIT_DN: 'appInitDn',
    APP_INIT_CARD_COD: 'appInitCardCod',
    APP_INIT_REDIRECT: 'appInitRedirect',
  },
  ANALYTICS: {
    ITAU_TRACK: 'itauTrack',
  },
};
