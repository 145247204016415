export default fullUrlQueryParams => {
  const keyArrays = [];
  const queryKeys = fullUrlQueryParams.split(/[?]|[&]/);

  queryKeys.forEach(key => {
    const pair = key.split('=');
    if (pair[0]) keyArrays.push(pair[0]);
  });
  return keyArrays;
};
