import { track } from '../track';

export default function* customLink(action) {
  const analytics = {
    cartao: { nome: 'Itaú' },
    custom: {
      itemClicado: action.itemClicado,
      events: ['cliqueEmBotao'],
    },
    rule: 'customLink',
  };

  yield track(analytics);
}
