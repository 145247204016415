import { call, put } from 'redux-saga/effects';

import constants from '../../constants';
import {
  getPageLoadRedirect,
  getRedirectMessage,
} from '../../endpoints/redirect';
import { Creators as AppActions } from '../ducks/app';
import { Creators as ErrorActions } from '../ducks/error';
import { Creators as RedirectActions } from '../ducks/redirect';
import * as domain from './domain';

const {
  ERROR_TYPES: {
    FLOW: { APP_INIT_REDIRECT },
  },
} = constants;

const shouldRenderEmptyPage = parameters =>
  parameters.dn === null && parameters.campanha === null;

function* dispatchPageLoad(dn) {
  const response = yield call(getPageLoadRedirect, dn);
  yield put(AppActions.pageLoad(response));
}

export function* appInitRedirect() {
  try {
    const parameters = domain.extractParametersFromUrl();
    if (shouldRenderEmptyPage(parameters)) {
      return yield put(RedirectActions.stopLoading());
    }
    const message = yield call(getRedirectMessage, parameters);
    yield dispatchPageLoad(parameters.dn);
    yield put(RedirectActions.setMessage(message));
    return yield put(RedirectActions.stopLoading());
  } catch (error) {
    yield put(ErrorActions.callErrorHandler(error, APP_INIT_REDIRECT));
    return yield put(RedirectActions.stopLoading());
  }
}
