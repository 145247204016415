import React from 'react';

import pageIntroItemPropTypes from './pageIntroItemPropTypes';
import './_PageIntroItem.scss';

const PageIntroItem = ({ descIcon, descTitle }) => (
  <div className="pageintrowhitelabel-item-wrapper">
    <i className={`description-item-icon ${descIcon}`} aria-hidden />

    <div className="description-item-line">
      <p className="description-item-title">{descTitle}</p>
    </div>
  </div>
);

PageIntroItem.propTypes = pageIntroItemPropTypes;

PageIntroItem.defaultProps = {
  descIcon: '',
  descTitle: '',
};

export default PageIntroItem;
