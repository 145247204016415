import React, { Component } from 'react';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';

import Loading from '../../components/Loading/Loading';
import constants from '../../constants';
import { Creators as AppActions } from '../../store/ducks/app';
import getParamsFromUrl from '../../util/getParamsFromUrl';
import isObjEmpty from '../../util/isObjEmpty';
import cardAcquisitionPropTypes from './cardAcquisitionPropTypes';
import './_CardAcquisition.scss';

const {
  QUERY_PARAMS: {
    EMBEDDED_FORM: { CHPRAS },
  },
} = constants;

class CardAcquisition extends Component {
  componentDidMount() {
    const { linksItaucard, appInit } = this.props;

    if (isObjEmpty(linksItaucard)) {
      appInit();
    }
  }

  getMainCardLogo = () => {
    const { imagesItaucard } = this.props;

    return imagesItaucard['main-card-acquisition-logo-image'].url;
  };

  getMainCardLogoAlt = () => {
    const { imagesItaucard } = this.props;

    return imagesItaucard['main-card-acquisition-logo-image'].alt;
  };

  getAcquisitionUrl = () => {
    const { linksItaucard, customerChpras } = this.props;
    let acquisitionLink = linksItaucard['iframe-acquisition-url'].url;

    let queryString = getParamsFromUrl();

    const shouldSendParameters = queryString !== '';
    if (shouldSendParameters) {
      queryString = decodeURIComponent(queryString);
      const queryParamFormatted = this.getQueryParam(
        linksItaucard['iframe-acquisition-url-without-parameters'].url,
        queryString
      );
      acquisitionLink = linksItaucard[
        'iframe-acquisition-url-without-parameters'
      ].url.concat(queryParamFormatted);
    }
    if (customerChpras)
      acquisitionLink += acquisitionLink.includes('?')
        ? `&${CHPRAS}=${customerChpras}`
        : `?${CHPRAS}=${customerChpras}`;
    return acquisitionLink;
  };

  getQueryParam = (linkItauCard, queryString) => {
    if (linkItauCard.includes('?')) {
      return `&${queryString}`;
    }
    return `?${queryString}`;
  };

  render() {
    const { linksItaucard /* , browserWhitelabel */ } = this.props;

    if (isObjEmpty(linksItaucard)) {
      return (
        <div className="itaucard-theme-default itau-loading-wrapper">
          <Loading loadPrimary={false} />
        </div>
      );
    }

    if (this.getAcquisitionUrl()) {
      window.location.replace(this.getAcquisitionUrl());
    }
    return <div />;
  }
}

CardAcquisition.propTypes = cardAcquisitionPropTypes;

CardAcquisition.defaultProps = {
  browserWhitelabel: '',
};

const mapStateToProps = state => ({
  imagesItaucard: state.whiteLabel.images,
  linksItaucard: state.whiteLabel.links,
  browserWhitelabel: state.app.browser,
  customerChpras: state.share.shareInfo.clientId,
});

const mapDispatchToProps = dispatch => bindActionCreators(AppActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CardAcquisition);
