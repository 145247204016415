import { call } from 'redux-saga/effects';

import api from '../config/api';
import environmentVariables from '../config/environmentVariables';

export function* getRedirectMessage(parameters) {
  const { GATEWAY_APP_KEY, MGM_AUTH } = environmentVariables;
  const KEY = GATEWAY_APP_KEY;
  const token = MGM_AUTH;
  const config = { headers: { Authorization: `Basic ${token}` } };
  const { data } = yield call(
    api.post,
    `/mgm/v2/redirect?gw-app-key=${KEY}`,
    parameters,
    config
  );
  return data.mensagem;
}

export function* getPageLoadRedirect(dn) {
  const dnNumber = Number(dn);
  const { GATEWAY_APP_KEY, MGM_AUTH } = environmentVariables;
  const token = MGM_AUTH;
  const config = { headers: { Authorization: `Basic ${token}` } };
  const KEY = GATEWAY_APP_KEY;
  const response = yield call(
    api.post,
    `/mgm-dn/v1/analytics/page-load/redirect?gw-app-key=${KEY}`,
    { dn: dnNumber },
    config
  );
  return response.data;
}
