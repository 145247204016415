import React from 'react';

import contentItemPropTypes from './contentItemPropTypes';
import './_ContentItem.scss';

const ContentItem = ({
  title,
  imageUrl,
  text,
  linkUrl,
  linkAria,
  idComponentContext,
  index,
}) => (
  <div className="component-benefitlist-contentitem-whitelabel-wrapper">
    <div className="card">
      <h2
        className="title"
        id={`lblTitle2_${idComponentContext}_BenefitCard${index}`}
      >
        {title}
      </h2>
      <img src={imageUrl} alt="" className="image" />
      <p className="text">{text}</p>
      {linkUrl !== '' && (
        <a
          id={`lnkNav_${idComponentContext}_BenefitCard${index}`}
          className="link"
          href={linkUrl}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={linkAria}
        >
          <i className="icon icon-itaufonts_mais" aria-hidden />
        </a>
      )}
    </div>
  </div>
);

ContentItem.propTypes = contentItemPropTypes;

export default ContentItem;
