import React, { Component } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import Loading from '../../components/Loading/Loading';
import constants from '../../constants';
// eslint-disable-next-line import/no-named-as-default
import DefaultErrorRedirect from '../../pages/DefaultErrorRedirect/DefaultErrorRedirect';
import { Creators as ErrorActions } from '../../store/ducks/error';
import { Creators as RedirectActions } from '../../store/ducks/redirect';
import getBrowser from '../../util/getBrowser';

const {
  BUSINESS_RULES: { MAX_MANUAL_RETRY },
} = constants;

const isBrowserIe = getBrowser() === 'ie';

class AppRedirect extends Component {
  componentDidMount() {
    const { appInitRedirect } = this.props;
    appInitRedirect();
  }

  showRetryActionButton = () => {
    const { errorRetryCount } = this.props;

    return errorRetryCount < MAX_MANUAL_RETRY;
  };

  render() {
    const {
      loading,
      message,
      tryAgain,
      errorRetryCount,
      hasError,
    } = this.props;

    require('../../styles/themes/itaucard-theme-default.css');

    if (loading) {
      return (
        <div className="itau-loading-wrapper">
          <Loading loadPrimary={false} />
        </div>
      );
    }
    if (hasError) {
      return (
        <div className="App itaucard-theme-default">
          <DefaultErrorRedirect
            retryCount={errorRetryCount}
            retry={this.showRetryActionButton()}
            retryAction={tryAgain}
            isBrowserIe={isBrowserIe}
          />
        </div>
      );
    }
    if (message) {
      window.location.replace(message);
    }
    return <div />;
  }
}

AppRedirect.propTypes = {
  appInitRedirect: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  tryAgain: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  errorRetryCount: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
  loading: state.redirect.loading,
  message: state.redirect.message,
  errorRetryCount: state.error.manualRetryCount,
  hasError: state.error.hasError,
  appInitRedirect: state.redirect.appInitRedirect,
  tryAgain: state.error.tryAgain,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...ErrorActions, ...RedirectActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AppRedirect);
