import PropTypes from 'prop-types';

export default {
  idComponentContext: PropTypes.string.isRequired,
  mainTitle: PropTypes.string.isRequired,
  reference: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};
