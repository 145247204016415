import React from 'react';

import ProductDetailItem from './ProductDetailItem/ProductDetailItem';
import './_ProductDetailList.scss';
import productDetailPropTypes from './productDetailPropTypes';

const idComponent = 'productDetailListWhiteLabel';

const ProductDetailList = ({
  idComponentContext,
  listTitle,
  listContent,
  anchor,
}) => (
  <section id={anchor} className="productdetailwhitelabel-list-wrapper">
    <div className="container" id={`${idComponent}_${idComponentContext}`}>
      <h2
        className="list-title"
        id={`lblTitle_${idComponent}_${idComponentContext}`}
      >
        {listTitle}
      </h2>

      <ul className="content-list">
        {listContent &&
          listContent.map((contentItem, index) => (
            <li
              key={contentItem.description.text}
              className="content-item"
              id={`lstItem${index}_${idComponent}_${idComponentContext}`}
            >
              <ProductDetailItem
                idComponentContext={idComponentContext}
                index={index}
                itemIcon={contentItem.icon.name}
                itemTitle={contentItem.title.text}
                itemText={
                  contentItem.description.text
                    ? contentItem.description.text
                    : ''
                }
                itemLink={contentItem.link ? contentItem.link.text : ''}
                itemUrl={contentItem.link ? contentItem.link.url : ''}
              />
            </li>
          ))}
      </ul>
    </div>
  </section>
);

ProductDetailList.propTypes = productDetailPropTypes;

export default ProductDetailList;
