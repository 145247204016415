import { put } from 'redux-saga/effects';

import {
  extractErrorStatus,
  extractEndpointUrlWithError,
} from '../../../../util';
import { Creators as AppActions } from '../../../ducks/app';
import { Creators as ErrorActions } from '../../../ducks/error';

const { appFinishLoading } = AppActions;
const { setErrorConditions } = ErrorActions;

export default function* genericErrorHandler(error, whereErrorOccurred) {
  const errorStatus = extractErrorStatus(error);
  const endpointUrl = extractEndpointUrlWithError(error);
  const hasCriticalError = true;

  const errorConditionsObject = {
    errorStatus,
    whereErrorOccurred,
    endpointUrl,
    hasCriticalError,
  };

  yield put(setErrorConditions(errorConditionsObject));
  return yield put(appFinishLoading());
}
