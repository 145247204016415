import React from 'react';

import './_ErrorContent.scss';
import DefaultErrorImageDesktop from '../assets/default_error_desktop.png';
import DefaultErrorImageMobile from '../assets/default_error_mobile.png';
import defaultErrorContentPropTypes from './defaultErrorContentPropTypes';

const idComponentContext = 'componentHeader';

const ErrorContent = ({
  title,
  description,
  link,
  linkNoRetry,
  retry,
  retryAction,
  descriptionList,
}) => (
  <div className="ErrorContent">
    <div className="error-content-wrapper">
      <div className="error-header-block">
        <img
          alt=""
          src={DefaultErrorImageMobile}
          className="error-img-mobile"
        />
        <img
          alt=""
          src={DefaultErrorImageDesktop}
          className="error-img-desktop"
        />
      </div>
      <div className="error-content-block">
        <h2
          id={`lblTitle_${idComponentContext}`}
          className="error-title"
          aria-label="algo deu errado"
        >
          {title}
        </h2>
        <p
          id={`lblContent_${idComponentContext}`}
          className="error-description"
        >
          {description}

          {descriptionList.length > 0 && (
            <ul className="error-description-list">
              {descriptionList.map((descriptionItem, index) => (
                <li key={descriptionItem} className="error-description-item">
                  {`${index + 1}- ${descriptionItem}`}
                </li>
              ))}
            </ul>
          )}
        </p>
        {retry ? (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            role="link"
            id={`lnkRetry_${idComponentContext}`}
            type="button"
            tabIndex={0}
            className="retry-button"
            onClick={() => {
              retryAction();
            }}
            onKeyDown={() => {
              retryAction();
            }}
          >
            {link}
          </a>
        ) : (
          <p
            id={`lblRetry_${idComponentContext}`}
            className="error-description"
          >
            {linkNoRetry}
          </p>
        )}
      </div>
    </div>
  </div>
);

ErrorContent.propTypes = defaultErrorContentPropTypes;

ErrorContent.defaultProps = {
  title: 'Página de erro',
  description: 'Algo deu errado',
  link: 'tentar novamente',
  retry: false,
  descriptionList: [],
};

export default ErrorContent;
