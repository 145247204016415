import { put, select } from 'redux-saga/effects';

import constants from '../../../constants';
import { Creators as AppActions } from '../../ducks/app';
import { Creators as ErrorActions } from '../../ducks/error';
import { Creators as RedirectActions } from '../../ducks/redirect';

const {
  BUSINESS_RULES: { MAX_MANUAL_RETRY },
  ERROR_TYPES: {
    FLOW: { APP_INIT_DN, APP_INIT_CARD_COD, APP_INIT_REDIRECT },
  },
} = constants;

const { appInit, appInitByCardCode } = AppActions;
const { addOneToManualRetryCount } = ErrorActions;
const { appInitRedirect } = RedirectActions;

export default function* tryAgainHandler() {
  const { manualRetryCount, whereErrorOccurred } = yield select(
    state => state.error
  );
  const canManualRetry = manualRetryCount < MAX_MANUAL_RETRY;

  if (canManualRetry) {
    yield put(addOneToManualRetryCount());

    switch (whereErrorOccurred) {
      case APP_INIT_DN:
        yield put(appInit());
        break;
      case APP_INIT_CARD_COD:
        yield put(appInitByCardCode());
        break;
      case APP_INIT_REDIRECT:
        yield put(appInitRedirect());
        break;
      default:
        break;
    }
  }
}
